import { Api } from "@quipa/api";

/**
 * Update account information. For now only updates/deletes credit card
 * @param id
//  * @param cardToken
//  * @param cardDigits
 */
// export const updateAccountCard = (id: number, cardToken: string | null, cardDigits: string | null) =>
//     Api.createAction(Api.UPDATE_ACCOUNT, { accountId: id,
//         cardToken, cardDigits
//     }, { authenticated: true });

/**
 * Update account settings
 * @param id Account id
 * @param settings Account settings
 */
export const updateAccountCalcSettings = (
    id: number,
    settings: Api.AccountCalculatorDefaultValues,
) =>
    Api.createAction(
        Api.UPDATE_ACCOUNT,
        { accountId: id },
        { authenticated: true },
    );

// /**
//  * Pay account
//  * @param id
//  */
// export const payAccount = (id: number) =>
//     Api.createAction(Api.PAY_ACCOUNT, { accountId: id }, { authenticated: true });

/**
 * Subscribe account
 * @param id
 */
export const subscribeAccount = (
    id: number,
    paymentMethod?: string,
    planType?: string,
    couponCode?: string,
    isFirstSubscribe?: boolean,
) =>
    Api.createAction(
        Api.SUBSCRIBE_ACCOUNT,
        {
            accountId: id,
            paymentMethod,
            planType,
            couponCode,
            isFirstSubscribe,
        },
        { authenticated: true },
    );

/**
 * Subscribe account
 * @param id
 */
export const betaSubscribeAccount = (id: number) =>
    Api.createAction(
        Api.BETA_SUBSCRIBE_ACCOUNT,
        { accountId: id },
        { authenticated: true },
    );

/**
 * Return billing history for the account
 * @param id
 */
export const getBillingHistory = (id: number) =>
    Api.createAction(
        Api.GET_ACCOUNT_BILLING_HISTORY,
        { accountId: id },
        { authenticated: true },
    );

/**
 * Return current plan info for the account
 * @param id
 */
export const getCurrentPlanInfo = (id: number) =>
    Api.createAction(
        Api.GET_CURRENT_PLAN_INFO,
        { accountId: id },
        { authenticated: true },
    );

/**
 * Return current plan info for the account
 * @param id
 */
export const getPlans = (id: number) =>
    Api.createAction(
        Api.GET_PLANS,
        { accountId: id },
        { authenticated: true },
    );

/**
 * Connect account to quickbook account
 * @param accountId
 * @param code
 * @param realmId
 */
export const connectToQuickbook = (
    accountId: number,
    code: string,
    realmId: string,
) =>
    Api.createAction(
        Api.CONNECT_TO_QUICKBOOK,
        { accountId, code, realmId },
        { authenticated: true },
    );

/**
 * Upload image
 * @param accountId
 * @param file
 */
export const uploadImage = (accountId: number, file: File) =>
    Api.createAction(
        Api.UPLOAD_IMAGE,
        { accountId, data: file },
        { authenticated: true },
    );

/**
 * Return billing history for the account
 * @param id
 */
export const getAccount = (id: number) =>
    Api.createAction(
        Api.GET_ACCOUNT,
        { accountId: id },
        { authenticated: true },
    );

/**
 * Update account settings
 * @param id Account id
 * @param settings Account settings
 */
export const updateAccountSettings = (
    id: number,
    billingInfo?: Api.BillingInfo,
    clientFacingInfo?: Api.BillingInfo,
    sameAsBilling?: boolean,
    avatarId?: string | null,
    isModalOpen?: boolean,
    ripplefoldPopUp?: number,
    otherItemPopUp?: number,
    romanShadePopUp?: number,
) =>
    Api.createAction(
        Api.UPDATE_ACCOUNT,
        {
            accountId: id,
            avatarId,
            billingInfo,
            clientFacingInfo,
            sameAsBilling,
            isModalOpen,
            ripplefoldPopUp,
            romanShadePopUp,
            otherItemPopUp
        },
        { authenticated: true },
    );

export const unsubscribeAccount = (id: number) =>
    Api.createAction(Api.UNSUBSCRIBE_ACCOUNT, { accountId: id }, {});

export const validatePromotionCode = (accountId: number, code: string) =>
    Api.createAction(Api.VALIDATE_PROMOTION, { accountId, code });
